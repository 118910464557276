import { Component } from '@angular/core';

import { Location } from "@angular/common";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'CDPF';

  activeTab = 'home';

  constructor(private location: Location){
    let path = this.location.path();

    if(path == '/mission'){
      this.activeTab = 'mission';
    }
    else if(path == '/project'){
      this.activeTab = 'project';
    }
    else if(path == '/about'){
      this.activeTab = 'about';
    }
    else if(path == '/contact'){
      this.activeTab = 'contact';
    }
    else if (path == '/gallery') {
      this.activeTab = 'gallery';
    }
  }

  setActive(type){
    this.activeTab = type;
  }
}
