import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { MissionComponent } from './mission/mission.component';
import { ProjectComponent } from './project/project.component';
import { ContactComponent } from './contact/contact.component';

//Get routing module
import { AppRoutingModule } from "./app.routing-module";

//Get the gallery
import { NgxGalleryModule } from "ngx-gallery";
import { GalleryComponent } from './gallery/gallery.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    MissionComponent,
    ProjectComponent,
    ContactComponent,
    GalleryComponent
  ],
  imports: [
    BrowserModule,
    NgxGalleryModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
