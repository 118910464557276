import { Component, OnInit,ViewChild } from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation, NgxGalleryComponent } from 'ngx-gallery';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})


export class GalleryComponent implements OnInit {

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  @ViewChild('gallery') gallery: NgxGalleryComponent;

  constructor() { }

  
  moveThumbnailsRight(){
    this.gallery.moveThumbnailsRight();
  }

  moveThumbnailsLeft(){
    this.gallery.moveThumbnailsLeft();
  }
  ngOnInit() {
    this.galleryOptions = [
      {
        // "previewFullscreen": true, 
        // "previewKeyboardNavigation": true,
        // "imageAutoPlay": true, 
        // "imageAutoPlayPauseOnHover": true, 
        // "previewAutoPlay": true, 
        // "previewAutoPlayPauseOnHover": true,
        image:false,
        width: '100%',
        height: '600px',
        thumbnailsColumns: 3,
        thumbnailsRows:2,
        imageArrows: false, 
        thumbnailsArrows: false,
        imageAnimation: NgxGalleryAnimation.Slide,
        //imageSize: 'contain'
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        //height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
       
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false
      }
    ];

    this.galleryImages = [
      {
        small: 'assets/gallery/1.jpg',
        medium: 'assets/gallery/1.jpg',
        big: 'assets/gallery/1.jpg'
      },
      {
        small: 'assets/gallery/2.jpg',
        medium: 'assets/gallery/2.jpg',
        big: 'assets/gallery/2.jpg'
      },
      {
        small: 'assets/gallery/3.jpg',
        medium: 'assets/gallery/3.jpg',
        big: 'assets/gallery/3.jpg'
      },
      {
        small: 'assets/gallery/4.jpg',
        medium: 'assets/gallery/4.jpg',
        big: 'assets/gallery/4.jpg'
      },
      {
        small: 'assets/gallery/5.jpg',
        medium: 'assets/gallery/5.jpg',
        big: 'assets/gallery/5.jpg'
      },
      {
        small: 'assets/gallery/6.jpg',
        medium: 'assets/gallery/6.jpg',
        big: 'assets/gallery/6.jpg'
      },
      {
        small: 'assets/gallery/7.jpg',
        medium: 'assets/gallery/7.jpg',
        big: 'assets/gallery/7.jpg'
      },
      {
        small: 'assets/gallery/8.jpg',
        medium: 'assets/gallery/8.jpg',
        big: 'assets/gallery/8.jpg'
      },
      {
        small: 'assets/gallery/9.jpg',
        medium: 'assets/gallery/9.jpg',
        big: 'assets/gallery/9.jpg'
      },
      {
        small: 'assets/gallery/10.jpg',
        medium: 'assets/gallery/10.jpg',
        big: 'assets/gallery/10.jpg'
      },
      {
        small: 'assets/gallery/11.jpg',
        medium: 'assets/gallery/11.jpg',
        big: 'assets/gallery/11.jpg'
      },
      {
        small: 'assets/gallery/12.jpg',
        medium: 'assets/gallery/12.jpg',
        big: 'assets/gallery/12.jpg'
      },
      {
        small: 'assets/gallery/13.jpg',
        medium: 'assets/gallery/13.jpg',
        big: 'assets/gallery/13.jpg'
      },
      {
        small: 'assets/gallery/14.jpg',
        medium: 'assets/gallery/14.jpg',
        big: 'assets/gallery/14.jpg'
      },
      {
        small: 'assets/gallery/15.jpg',
        medium: 'assets/gallery/15.jpg',
        big: 'assets/gallery/15.jpg'
      },
      {
        small: 'assets/carousel/c6.jpg',
        medium: 'assets/carousel/c6.jpg',
        big: 'assets/carousel/c6.jpg'
      },
      {
        small: 'assets/carousel/c7.jpg',
        medium: 'assets/carousel/c7.jpg',
        big: 'assets/carousel/c7.jpg'
      },
      {
        small: 'assets/carousel/c1.jpg',
        medium: 'assets/carousel/c1.jpg',
        big: 'assets/carousel/c1.jpg'
      }
    ];
  }

}
