import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

//Import Components
import { HomeComponent } from "./home/home.component";
import { AboutComponent } from "./about/about.component";
import { ContactComponent } from "./contact/contact.component";
import { MissionComponent } from "./mission/mission.component";
import { ProjectComponent } from "./project/project.component";
import { GalleryComponent } from "./gallery/gallery.component";

//Define Routes
const routes: Routes = [
    {
        path:'home',
        component: HomeComponent
    },
    {
        path:'about',
        component: AboutComponent
    },
    {
        path:'contact',
        component: ContactComponent
    },
    {
        path:'mission',
        component: MissionComponent
    },
    {
        path:'project',
        component:ProjectComponent
    },
    {
        path:'gallery',
        component: GalleryComponent
    },
    {
        path:'**',
        redirectTo:'/home',
        pathMatch:'full'
    }
]

@NgModule({
    imports:[RouterModule.forRoot(routes)],
    exports:[RouterModule]
})

export class AppRoutingModule{}